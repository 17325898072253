import AuthService from '../service/auth'
import toast from './toast';
import Loader from './loader';
export const state = {
  user: {},
  user_access: {},
  users: [],
};

export const getters = {
  getUser(state) {
    return state.user
  },
}

export const mutations = {
  SHOW_USER(state, payload) {
    state.user = payload;
  },
  GET_USERS(state, payload) {
    state.users = payload;
  },
  SHOW_USER_ACCESS(state, payload) {
    state.user_access = payload;
  }
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  allAdmin({ commit, actions }, query) {
    return AuthService.allAdmin(query)
      .then(response => {
        commit('GET_USERS', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error.response.data.message))
  },
  allManager({ commit }, query) {
    return AuthService.allManager(query)
      .then(response => {
        commit('GET_USERS', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error.response.data.message))
  },
  allStaffs({ commit }, query) {
    return AuthService.allStaffs(query)
      .then(response => {
        commit('GET_USERS', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error.response.data.message))
  },
  allClient({ commit }, query) {
    return AuthService.allClient(query)
      .then(response => {
        commit('GET_USERS', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error.response.data.message))
  },
  allVendors({ commit }, query) {
    return AuthService.allVendors(query)
      .then(response => {
        commit('GET_USERS', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error.response.data.message))
  },
  allTenants({ commit }, query) {
    return AuthService.allTenants(query)
      .then(response => {
        commit('GET_USERS', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error.response.data.message))
  },
  // eslint-disable-next-line no-unused-vars
  showUser({ commit, actions }, id) {
    return AuthService.user(id)
      .then(response => {
        commit('SHOW_USER', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error))
  },
    // eslint-disable-next-line no-unused-vars
    showStaff({ commit, actions }, id) {
      return AuthService.showStaff(id)
        .then(response => {
          // commit('SHOW_USER', response.data)
          return Promise.resolve(response)
        })
        .catch(error => Promise.reject(error))
    },
  // eslint-disable-next-line no-unused-vars
  showTenant({ commit, actions }, id) {
    return AuthService.showTenant(id)
      .then(response => {
        // commit('SHOW_USER', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error))
  },
    // eslint-disable-next-line no-unused-vars
    showClient({ commit, actions }, id) {
      return AuthService.showClient(id)
        .then(response => {
          // commit('SHOW_USER', response.data)
          return Promise.resolve(response)
        })
        .catch(error => Promise.reject(error))
    },

      // eslint-disable-next-line no-unused-vars
  showVendor({ commit, actions }, id) {
    return AuthService.showVendor(id)
      .then(response => {
        // commit('SHOW_USER', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error))
  },
    // eslint-disable-next-line no-unused-vars
    showUserAccess({ commit, actions }, id) {
      return AuthService.showUserAccess(id)
        .then(response => {
          commit('SHOW_USER_ACCESS', response.data)
          return Promise.resolve(response)
        })
        .catch(error => Promise.reject(error))
    },
  // eslint-disable-next-line no-unused-vars
  loginUser({ commit, actions }, data) {
    return AuthService.login(data)
      .then(response => {
        Promise.resolve(response)
      })
      .catch(error => {
        toast(error.response.data.error, 'error')
        return Promise.reject(error.response)
      })
  },
  // eslint-disable-next-line no-unused-vars
  logoutUser({ commit, actions }, data) {
    return AuthService.logout(data)
      .then(response => Promise.resolve(response))
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
    // eslint-disable-next-line no-unused-vars
    forgetPassword({ commit, actions }, data) {
      Loader.open()
      return AuthService.forgetPassword(data)
        .then(response => {
          Loader.close()
          toast(response.message, 'info')
          Promise.resolve(response)
        })
        .catch(error => {
          Loader.close()
          toast(error.response.data.message, 'error')
          return Promise.reject(error.response)
        })
    },
        // eslint-disable-next-line no-unused-vars
        resetPassword({ commit, actions }, data) {
          Loader.open()
          return AuthService.resetPassword(data)
            .then(response => {
              Loader.close()
              toast(response.message)
              Promise.resolve(response)
            })
            .catch(error => {
              Loader.close()
              toast(error.response.data.message, 'error')
              return Promise.reject(error.response)
            })
        },
  // eslint-disable-next-line no-unused-vars
  registerUser({ commit, actions }, data) {
    return AuthService.register(data)
      .then(response => Promise.resolve(response))
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
  // eslint-disable-next-line no-unused-vars
  registerAdmin({ commit, actions }, data) {
    return AuthService.registerAdmin(data)
      .then(response => {
        toast(response.data.message)
        return Promise.resolve(response)
      })
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
  // eslint-disable-next-line no-unused-vars
  registerManager({ commit, actions }, data) {
    return AuthService.registerManager(data)
    .then(response => {
      toast(response.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        toast(error.response.data, 'error')
        return Promise.reject(error.response)
      })
  },

  // eslint-disable-next-line no-unused-vars
  registerStaff({ commit, actions }, data) {
    Loader.open()
    return AuthService.registerStaff(data)
    .then(response => {
      Loader.close()
      toast(response.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        Loader.close()
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
    // eslint-disable-next-line no-unused-vars
  registerTenant({ commit, actions }, data) {
    Loader.open()
    return AuthService.registerTenant(data)
    .then(response => {
      Loader.close()
      toast(response.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        Loader.close()
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
    // eslint-disable-next-line no-unused-vars
  registerClient({ commit, actions }, data) {
    Loader.open()
    return AuthService.registerClient(data)
    .then(response => {
      Loader.close()
      toast(response.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        Loader.close()
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },

      // eslint-disable-next-line no-unused-vars
  registerVendor({ commit, actions }, data) {
    Loader.open()
        return AuthService.registerVendor(data)
        .then(response => {
          Loader.close()
          toast(response.message)
          return Promise.resolve(response)
        })
          .catch(error => {
            Loader.close()
            toast(error.response.data.message, 'error')
            return Promise.reject(error.response)
          })
      },

  // eslint-disable-next-line no-unused-vars
  updateUser({ commit, actions }, data) {
    Loader.open()
    return AuthService.update(data.id, data)
    .then(response => {
      Loader.close()
      toast(response.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        Loader.close()
        toast(error.response.data.errors, 'error')
        return Promise.reject(error.response)
      })
  },
    // eslint-disable-next-line no-unused-vars
    toggleUserAccess({ commit, actions }, data) {
      return AuthService.toggleUserAccess(data.id, data)
      .then(response => {
        toast(response.data.message)
        return Promise.resolve(response)
      })
        .catch(error => {
          toast(error.response.data.message, 'error')
          return Promise.reject(error.response)
        })
    },
  // eslint-disable-next-line no-unused-vars
  updateStaff({ commit, actions }, data) {
    return AuthService.updateStaff(data.id, data)
    .then(response => {
      if(data.is_profile_update === true) {
        const info = response.data 
        info.user_type = localStorage.getItem('sweet_homes_america_user_type')
        localStorage.setItem('sweet_homes_america_user', JSON.stringify(response.data))
      }
      toast(response.message.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
    // eslint-disable-next-line no-unused-vars
  updateManager({ commit, actions }, data) {
  return AuthService.updateManager(data.id, data)
  .then(response => {
    if(data.is_profile_update === true) {
      const info = response.data 
      info.user_type = localStorage.getItem('sweet_homes_america_user_type')
      localStorage.setItem('sweet_homes_america_user', JSON.stringify(response.data))
    }
    toast(response.message.message)
    return Promise.resolve(response)
  })
    .catch(error => {
      toast(error.response.data.message, 'error')
      return Promise.reject(error.response)
    })
},  // eslint-disable-next-line no-unused-vars
updateAdmin({ commit, actions }, data) {
    return AuthService.updateAdmin(data.id, data)
    .then(response => {
      if(data.is_profile_update === true) {
        const info = response.data 
        info.user_type = localStorage.getItem('sweet_homes_america_user_type')
        localStorage.setItem('sweet_homes_america_user', JSON.stringify(response.data))
      }
      toast(response.message.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },

    // eslint-disable-next-line no-unused-vars
    updateClient({ commit, actions }, data) {
      return AuthService.updateClient(data.id, data)
      .then(response => {
        if(data.is_profile_update === true) {
          const info = response.data 
          info.user_type = localStorage.getItem('sweet_homes_america_user_type')
          localStorage.setItem('sweet_homes_america_user', JSON.stringify(response.data))
        }
        toast(response.message.message)
        return Promise.resolve(response)
      })
        .catch(error => {
          toast(error.response.data.message, 'error')
          return Promise.reject(error.response)
        })
    },

      // eslint-disable-next-line no-unused-vars
    updateTenant({ commit, actions }, data) {
    return AuthService.updateTenant(data.id, data)
    .then(response => {
      if(data.is_profile_update === true) {
        const info = response.data 
        info.user_type = localStorage.getItem('sweet_homes_america_user_type')
        localStorage.setItem('sweet_homes_america_user', JSON.stringify(response.data))
      }
      toast(response.message.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },

    // eslint-disable-next-line no-unused-vars
    updateVendor({ commit, actions }, data) {
      return AuthService.updateVendor(data.id, data)
      .then(response => {
        if(data.is_profile_update === true) {
          const info = response.data 
          info.user_type = localStorage.getItem('sweet_homes_america_user_type')
          localStorage.setItem('sweet_homes_america_user', JSON.stringify(response.data))
        }
        toast(response.message.message)
        return Promise.resolve(response)
      })
        .catch(error => {
          toast(error.response.data.message, 'error')
          return Promise.reject(error.response)
        })
    },
  // eslint-disable-next-line no-unused-vars
  deleteUser({ commit, actions }, id) {
    return AuthService.delete(id)
      .then(response => Promise.resolve(response))
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
  // eslint-disable-next-line no-unused-vars
  updateVisibileToWebsitePosition({ commit, actions }, data) {
    return AuthService.updateVisibileToWebsitePosition(data)
    .then(response => {
      // toast(response.message)
      return Promise.resolve(response)
    })
      .catch(error => {
        toast(error.response.data.message, 'error')
        return Promise.reject(error.response)
      })
  },
}