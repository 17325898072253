import Axios from 'axios';
import config from '../../config';

const accessToken = localStorage.getItem('sweet_homes_america_token')

export const axios = Axios.create({
	baseURL: config[process.env.NODE_ENV].baseURL,
	headers: {
		'Access-Control-Allow-Origin':'*',
		'Access-Control-Allow-Methods':'*',
		'Access-Control-Allow-Headers':"Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization",
		'Accpet':'application-json',
        Authorization: `Bearer ${accessToken}`,
	}
});

export const mainAxios = Axios.create({
	baseURL: config[process.env.NODE_ENV].baseURL,
});