import FormOfPaymentService from '../service/form_of_payments'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        form_of_payments: [],
        form_of_payment: {},
    },
};

export const getters = {
    getFormOfPayment(state) {
        return state.form_of_payments
    },
}

export const mutations = {
    GET_FORM_OF_PAYMENT(state, data) {
        state.form_of_payments = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allFormOfPayment({ commit, actions }, query) {
        return FormOfPaymentService.index(query)
            .then(response => {
                commit('GET_FORM_OF_PAYMENT', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createFormOfPayment({ commit, actions }, data) {
        Loader.open()
        return FormOfPaymentService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateFormOfPayment({ commit, actions }, data) {
        return FormOfPaymentService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteFormOfPayment({ commit, actions }, id) {
        return FormOfPaymentService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    markAsCancel({ commit, actions }, data) {
        return FormOfPaymentService.markAsCancel(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}