import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('properties', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getPropertyByLeaseStartDateAndLeaseEndDate(params) {
    return axios
      .get('get-property-by-lease-start-date-and-lease-end-date', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getPropertyByAvailabilityDate(params) {
    return axios
      .get('get-property-by-availability-date', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getPropertyByPropertyOwnerWithStatus(params) {
    return axios
      .get('get-property-by-property-owner-with-status', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getPropertyByStatus(params) {
    return axios
      .get('get-property-by-status', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getPropertyByPropertyType(params) {
    return axios
      .get('get-property-by-property-type', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  show(id) {
    return axios
      .get(`properties/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  store(data) {
    return axios
      .post('properties', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  update(id, data) {
    return axios
      .put(`properties/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  delete(id) {
    return axios
      .delete(`properties/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  deletePropertyGallery(id) {
    return axios
      .delete(`property_galleries/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  storePropertyGallery(data) {
    return axios
      .post('property_galleries', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  orderPropertyGallery(data) {
    return axios
      .post('order-property-gallery', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  deleteAmenity(id) {
    return axios
      .delete(`amenities/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  storeAmenity(data) {
    return axios
      .post('amenities', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateAmenity(id, data) {
    return axios
      .put(`amenities/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  deleteParkingType(id) {
    return axios
      .delete(`parking_types/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  storeParkingType(data) {
    return axios
      .post('parking_types', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateParkingType(id, data) {
    return axios
      .put(`parking_types/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  deletePropertyType(id) {
    return axios
      .delete(`property_types/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  storePropertyType(data) {
    return axios
      .post('property_types', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updatePropertyType(id, data) {
    return axios
      .put(`property_types/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  deleteUtility(id) {
    return axios
      .delete(`utility_includes/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  storeUtility(data) {
    return axios
      .post('utility_includes', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateUtility(id, data) {
    return axios
      .put(`utility_includes/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
}
