import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import VueFileAgent from 'vue-file-agent';
import vSelect from "vue-select";
import VueLazyload from 'vue-lazyload';
import ToggleButton from 'vue-js-toggle-button'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vidle from 'v-idle'
import "vue-select/dist/vue-select.css";
import 'vue-file-agent/dist/vue-file-agent.css';
import "@/design/index.scss";
import "toastify-js/src/toastify.css"
import loadimage from '@/assets/images/img-loader.gif'
import Sparkline from 'vue-sparklines'
import moment from 'moment';
import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';
import * as formatDate from './helpers/formatDate'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.directive('date-format', {
  bind(el, binding) {
    el.value = moment(binding.value).format('YYYY-MM-DD');
  },
  update(el, binding) {
    el.value = moment(binding.value).format('YYYY-MM-DD');
  },
});


// const errorimage = require('./assets/error.gif')

Vue.use(BootstrapVue);
Vue.use(Vidle)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: errorimage,
  loading: loadimage, // Use the URL of the loading image here
  attempt: 1,
  filter: {
    progressive(listener) {
      const isWasabiS3 = /s3\.wasabisys\.com/;
      if (isWasabiS3.test(listener.src)) {
        listener.el.setAttribute('lazy-progressive', 'true');
        // Don't modify listener.loading here
      }
    },
    webp(listener, options) {
      if (!options.supportWebp) return;
      const isWasabiS3 = /s3\.wasabisys\.com/;
      if (isWasabiS3.test(listener.src)) {
        listener.src += '?format=webp';
      }
    }
  }
});
Vue.use(ToggleButton)
Vue.config.productionTip = false
Vue.use(VueFileAgent);
Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.component("v-select", vSelect);
Vue.use(Sparkline)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
// Vue.filter('truncate', truncate);
Object.keys(formatDate).forEach(key => {
  Vue.filter(key, formatDate[key])
})
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
