import store from '@/state/store'

export default [{
        path: '/',
        meta: {
            authRequired: true
        },
        name: 'home',
        component: () => import('./views/home'),
    },
    {
        path: '/staff-dashboard',
        meta: {
            authRequired: true
        },
        name: 'staff-dashboard',
        component: () => import('./views/account/Staff/Dashboard'),
    },
    {
        path: '/admin-dashboard',
        meta: {
            authRequired: true
        },
        name: 'admin-dashboard',
        component: () => import('./views/account/Admin/Dashboard'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
              
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./views/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./views/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('./views/account/reset-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? {
                    name: 'default'
                } : {
                    ...routeFrom
                })
            },
        },
    },
    {
        path: '/properties',
        name: 'properties',
        component: () => import('./views/property/property.vue'),
        meta: {
            authRequired: true,
        },
    },

    {
        path: '/all-staff',
        name: 'all-staff',
        component: () => import('./views/account/Manager/Manager.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/staffs',
        name: 'staffs',
        component: () => import('./views/account/Staff/Staff.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/clients',
        name: 'clients',
        component: () => import('./views/account/PropertyOwner/PropertyOwner.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/tenants',
        name: 'tenants',
        component: () => import('./views/account/Tenant/Tenant.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/vendors',
        name: 'vendors',
        component: () => import('./views/account/Vendor/Vendor.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/payments',
        name: 'payments',
        component: () => import('./views/payment/Payment.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/payment-categories',
        name: 'Payment Category',
        component: () => import('./views/settings/PaymentCategory.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/expenses',
        name: 'expenses',
        component: () => import('./views/expenses/Expenses.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/expenses-categories',
        name: 'Expenses Category',
        component: () => import('./views/settings/ExpensesCategory.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/form-of-payments',
        name: 'form-of-payments',
        component: () => import('./views/settings/FormOfPayment.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/configurations',
        name: 'configurations',
        component: () => import('./views/settings/Configurations.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/amenity',
        name: 'amenity',
        component: () => import('./views/settings/Amenity.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/utility-included',
        name: 'utility-included',
        component: () => import('./views/settings/UtilityInclude.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/parking-type',
        name: 'parking-type',
        component: () => import('./views/settings/ParkingType.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/property-type',
        name: 'property-type',
        component: () => import('./views/settings/PropertyType.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/vendor-types',
        name: 'vendor-types',
        component: () => import('./views/settings/VendorType.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/monthly-reports',
        name: 'monthly-reports',
        component: () => import('./views/reports/MonthlyReport.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: () => import('./views/page_content/Faqs.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('./views/page_content/PrivacyPolicy.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('./views/page_content/TermsOfService.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/team-members',
        name: 'team-members',
        component: () => import('./views/page_content/TeamMembers.vue'),
        meta: {
            authRequired: true,
        },
    },
]