import Axios from 'axios'
import { axios } from './index'
import config from '../../config'
import router from '../../router'
import Toastify from 'toastify-js';

export default {
  index(params) {
    return axios
      .get('users', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  /**
   *login in to api
   *@param {id} data. the data to use to fetch user data.
   */

  login(data) {
    return Axios.get(`${config[process.env.NODE_ENV].mainURL}/sanctum/csrf-cookie`).then(() => axios
      .post('login', data)
      .then(response => {
        localStorage.setItem('sweet_homes_america_token', response.data.token)
        localStorage.setItem('sweet_homes_america_user_type', response.data.user_type)
        localStorage.setItem('sweet_homes_america_user', JSON.stringify(response.data))
        Toastify({
            text: `Login successfully, welcome back ${response.data.first_name} ${response.data.last_name} !`,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            duration: 3000,
            style: {
                background: "linear-gradient(to right, #00b09b, #96c93d)",
              }
            }).showToast();    
            if(response.data.user_type === 'admin') {
              window.location = '/admin-dashboard'
            } else if(response.data.user_type === 'staff' && response.data.is_manager === 1) {
              window.location = '/'
            } else if(response.data.user_type === 'staff' && response.data.is_manager === 0) {
              window.location = '/staff-dashboard'
            }
           
        return Promise.resolve(response.data)
      })
      // eslint-disable-next-line no-trailing-spaces
      .catch(error => Promise.reject(error)))
  },

  /**
   *get single user data
   *@param {id} data. the data to use to fetch user data.
   */
  user() {
    return axios
      .get('user')
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  logout() {
    return axios
      .get('logout')
      .then(response => {
        localStorage.removeItem('sweet_homes_america_token')
        window.location = '/login'
        return Promise.resolve(response.data)
      })
      .catch(error => Promise.reject(error))
  },
  forgetPassword(data) {
    return axios
      .post('send-reset-link', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  resetPassword(data) {
    return axios
      .post('reset-password', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },

  register(data) {
    return Axios.get(`${config[process.env.NODE_ENV].mainURL}/sanctum/csrf-cookie`).then(() => axios
      .post('register', data)
      .then(response => {
        Toastify({
            text: "Account creatte successfully",
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            duration: 3000,
            style: {
                background: "linear-gradient(to right, #00b09b, #96c93d)",
              }
            }).showToast();    

            router.push('/login');
        return Promise.resolve(response.data)
      })
      // eslint-disable-next-line no-trailing-spaces
      .catch(error => Promise.reject(error)))
  },
  registerAdmin(data) {
    return axios
      .post('admin', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  registerManager(data) {
    return axios
      .post('managers', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  registerStaff(data) {
    return axios
      .post('staffs', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  registerTenant(data) {
    return axios
      .post('tenants', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  registerClient(data) {
    return axios
      .post('clients', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  registerVendor(data) {
    return axios
      .post('vendors', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateStaff(id, data) {
    return axios
      .put(`staffs/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateManager(id, data) {
    return axios
      .put(`managers/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateAdmin(id, data) {
    return axios
      .put(`admin/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateClient(id, data) {
    return axios
      .put(`clients/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateTenant(id, data) {
    return axios
      .put(`tenants/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  updateVendor(id, data) {
    return axios
      .put(`vendors/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  update(id, data) {
    return axios
      .put(`users/${id}`, data)
      .then(response => { 
        return Promise.resolve(response.data)
    })
      .catch(error => Promise.reject(error))
  },
  delete(id) {
    return axios
      .delete(`users/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  allAdmin(params) {
    return axios
      .get('admin', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  allManager(params) {
    return axios
      .get('managers', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  allStaffs(params) {
    return axios
      .get('staffs', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  allClient(params) {
    return axios
      .get('clients', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  allVendors(params) {
    return axios
      .get('vendors', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  allTenants(params) {
    return axios
      .get('tenants', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  showStaff(id) {
    return axios
      .get(`staffs/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  showTenant(id) {
    return axios
      .get(`tenants/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  showClient(id) {
    return axios
      .get(`clients/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  showVendor(id) {
    return axios
      .get(`vendors/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },


  /**
   *toggle user data
   *@param {id, data} data. the data to use to fetch user data.
   */
   toggleUserAccess(id, data) {
    return axios
      .put(`toggle-user-access/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  /**
   *get single user data
   *@param {id} data. the data to use to fetch user data.
   */
   showUserAccess(id) {
    return axios
      .get(`show-user-access/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  updateVisibileToWebsitePosition(data) {
    return axios
      .post('update-visible-to-website-position', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
}
