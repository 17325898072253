import UtilityItemService from '../service/utility_item'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        utility_items: [],
        utility_item: {},
    },
};

export const getters = {
    getUtilityItem(state) {
        return state.utility_items
    },
}

export const mutations = {
    GET_UTILITY_ITEMS(state, data) {
        state.utility_items = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allUtilityItem({ commit, actions }, query) {
        return UtilityItemService.index(query)
            .then(response => {
                commit('GET_UTILITY_ITEMS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createUtilityItem({ commit, actions }, data) {
        Loader.open()
        return UtilityItemService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateUtilityItem({ commit, actions }, data) {
        return UtilityItemService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteUtilityItem({ commit, actions }, id) {
        return UtilityItemService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
}