import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('settings', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  show(id) {
    return axios
      .get(`settings/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  store(data) {
    return axios
      .post('settings', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  update(id, data) {
    return axios
      .put(`settings/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  delete(id) {
    return axios
      .delete(`settings/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
}
