import VendorTypeService from '../service/vendor_type'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        vendor_types: [],
        vendor_type: {},
    },
};

export const getters = {
    getVendorType(state) {
        return state.vendor_types
    },
}

export const mutations = {
    GET_VENDOR_TYPE(state, data) {
        state.vendor_types = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allVendorType({ commit, actions }, query) {
        return VendorTypeService.index(query)
            .then(response => {
                commit('GET_VENDOR_TYPE', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createVendorType({ commit, actions }, data) {
        Loader.open()
        return VendorTypeService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateVendorType({ commit, actions }, data) {
        return VendorTypeService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteVendorType({ commit, actions }, id) {
        return VendorTypeService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    markAsCancel({ commit, actions }, data) {
        return VendorTypeService.markAsCancel(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}