import PageContentService from '../service/page_content'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        page_contents: [],
        privacy_policy_contnet: {},
        terms_of_service_content: {},
    },
};

export const getters = {
    getPageContent(state) {
        return state.faqs
    },
}

export const mutations = {
    GET_PAGE_CONTENTS(state, data) {
        state.page_contents = data
    },
    SHOW_PRIVACY_POLICY_CONTENT(state, data) {
        state.privacy_policy_contnet = data
    },
    SHOW_TERMS_OF_SERVICES_CONTENT(state, data) {
        state.terms_of_service_content = data
    }
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allPageContent({ commit }, query) {
        return PageContentService.index(query)
            .then(response => {
                commit('GET_PAGE_CONTENTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    showPrivacyPolicyContent({ commit }, query) {
        return PageContentService.getPageOfContent(query)
            .then(response => {
                commit('SHOW_PRIVACY_POLICY_CONTENT', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    showTermsOfServiceContent({ commit }, query) {
        return PageContentService.getPageOfContent(query)
            .then(response => {
                commit('SHOW_TERMS_OF_SERVICES_CONTENT', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createPageContent({ commit, actions }, data) {
        Loader.open()
        return PageContentService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updatePageContent({ commit, actions }, data) {
        return PageContentService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deletePageContent({ commit, actions }, id) {
        return PageContentService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}