import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('property-type-items', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  store(data) {
    return axios
      .post('property-type-items', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  update(id, data) {
    return axios
      .put(`property-type-items/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  delete(id) {
    return axios
      .delete(`property-type-items/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
}
