import PaymentCategoryService from '../service/payment_category'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        payment_categories: [],
        payment_category: {},
    },
};

export const getters = {
    getExpenses(state) {
        return state.payment_categories
    },
}

export const mutations = {
    GET_PAYMENT_CATEGORY(state, data) {
        state.payment_categories = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allPaymentCategory({ commit, actions }, query) {
        return PaymentCategoryService.index(query)
            .then(response => {
                commit('GET_PAYMENT_CATEGORY', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createPaymentCategory({ commit, actions }, data) {
        Loader.open()
        return PaymentCategoryService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updatePaymentCategory({ commit, actions }, data) {
        return PaymentCategoryService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deletePaymentCategory({ commit, actions }, id) {
        return PaymentCategoryService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    markAsCancel({ commit, actions }, data) {
        return PaymentCategoryService.markAsCancel(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}