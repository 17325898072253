import ParkingTypeItemService from '../service/parking_type_item'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        parking_type_items: [],
        parking_type_item: {},
    },
};

export const getters = {
    getParkingTypeItem(state) {
        return state.parking_type_items
    },
}

export const mutations = {
    GET_PARKING_TYPE_ITEMS(state, data) {
        state.parking_type_items = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allParkingTypeItem({ commit, actions }, query) {
        return ParkingTypeItemService.index(query)
            .then(response => {
                commit('GET_PARKING_TYPE_ITEMS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createParkingTypeItem({ commit, actions }, data) {
        Loader.open()
        return ParkingTypeItemService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateParkingTypeItem({ commit, actions }, data) {
        return ParkingTypeItemService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteParkingTypeItem({ commit, actions }, id) {
        return ParkingTypeItemService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars

}