import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('monthly-reports', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getMonthlyReportByDateRange(params) {
    return axios
      .get('get-monthly-report-by-date-range', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  store(data) {
    return axios
      .post('monthly-reports', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
}
